<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface Props {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
        min?: number;
        max?: number;
        step?: number;
    }
    import { FormInputData } from '~~/composables/useFormInput'
    
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: { default: 0.01 },
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean },
  min: { default: 0 },
  max: { default: 100 }
});
    const inputData: FormInputData = useFormInput(props);
</script>

<template>
    <FormFormattedNumberInput
        :inputName="inputName"
        :label="label"
        :placeholder="placeholder"
        :form="form"
        :model="model"
        :min="min"
        :max="max"
        :step="step"
        :mask="(value) => `${value}%`"
        :unmask="(value) => parseFloat(value.toString().replace('%', ''))"
        :disabled="inputData.props.disabled"
        :without-label="inputData.props.withoutLabel"
        :class-name="inputData.props.className"
        :element-class-name="inputData.props.elementClassName"
        :container-class-name="inputData.props.containerClassName"
    />
</template>
