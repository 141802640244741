<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface Props {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
        alignment?: 'top-full' | 'top-0' | 'bottom-0' | 'bottom-full';
        position?: 'left-full' | 'left-0' | 'right-0' | 'right-full' | 'center-x';
    }
    import VueDatePicker from '@vuepic/vue-datepicker'
    import { FormInputData } from '~~/composables/useFormInput'
    import '@vuepic/vue-datepicker/dist/main.css';
    
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean },
  alignment: { default: 'top-full' },
  position: { default: 'left-0' }
});
    const inputData: FormInputData = useFormInput(props);
    const date = computed({
        get() {
            return moment(inputData.model.value).toDate();
        },
        set(value) {
            inputData.form.set(inputData.inputName, moment(value).toDateTimeString());
        },
    });
    const formattedDate = computed(() => (inputData.model.value ? moment(inputData.model.value).toEUDateString() : ''));
</script>

<template>
    <Submenu
        :container="$theme('submenu.container.withoutPosition', 'p-4')"
        without-max-height
        :position="position"
        :alignment="alignment"
    >
        <template #default="{ toggle, show, hide }">
            <FormElement
                :input-data="inputData"
                @label-clicked="inputData.disabled ? null : show()"
            >
                <slot
                    name="input"
                    :toggle="toggle"
                    :show="show"
                    :hide="hide"
                >
                    <input
                        :id="inputData.inputId.value"
                        :required="inputData.required"
                        :placeholder="inputData.placeholder.value"
                        :class="inputData.className.value"
                        :name="inputData.props.inputName"
                        :value="formattedDate"
                        :disabled="inputData.disabled"
                        @focus="show"
                    />
                </slot>
            </FormElement>
        </template>
        <template #submenu="{ hide }">
            <slot
                name="datepicker"
                :hide="hide"
            >
                <VueDatePicker
                    v-model="date"
                    format="dd/MM/yyyy"
                    :enable-time-picker="false"
                    hide-input-icon
                    inline
                    :locale="useGetCurrentMomentLocale()"
                    auto-apply
                    :clearable="false"
                    :required="inputData.required.value"
                    :placeholder="inputData.placeholder.value"
                    @update:model-value="hide"
                />
            </slot>
        </template>
    </Submenu>
</template>
