<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface Props {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
        withCreateButton?: boolean;
        contactType: 'client' | 'supplier';
        openFromInvoiceForm?: boolean;
    }
    import Contact from '~/app/contact/models/Contact'
    import { FormInputData } from '~~/composables/useFormInput'
    
    const emit = defineEmits(['selected']);
    const select = ref(null);
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean },
  withCreateButton: { type: Boolean, default: false },
  contactType: null,
  openFromInvoiceForm: { type: Boolean, default: false }
});
    const inputData: FormInputData = useFormInput(props);
    const displayCreateButton = computed(() => props.withCreateButton && !inputData?.model.value);
    useListen('contact:contact:updated', (contact) => {
        if (inputData.model.value && contact.is(inputData.model.value)) {
            inputData.model.value = contact;
        }
    });
    useListen('contact:contact:deleted', (uuid) => {
        if (inputData.model?.value?.getId() === uuid) {
            inputData.model.value = null;
        }
    });
    const selectContact = (contact: Contact | null) => {
        inputData.model.value = contact;
        emit('selected', contact);
    };
</script>

<template>
    <FormElement
        :input-data="inputData"
        @label-clicked="select?.show()"
    >
        <SelectContact
            ref="select"
            :contact-type="contactType"
            :initial-value="inputData.model.value"
            :with-create-button="displayCreateButton"
            @selected="selectContact"
        >
            <template
                v-if="inputData.model.value"
                #button
            >
                <div class="flex items-center h-full space-x-3">
                    <a
                        href="#"
                        class="text-xs text-gray-600 transition-all cursor-pointer hover:text-primary"
                        :data-tooltip="$t('actions.edit')"
                        @click.stop.prevent="inputData.model.value.edit({ openFromInvoiceForm })"
                    >
                        <i class="fa-regular fa-pencil" />
                    </a>
                    <a
                        href="#"
                        class="text-sm text-gray-600 transition-all cursor-pointer hover:text-primary"
                        :data-tooltip="$t('actions.unselect')"
                        @click.stop.prevent="inputData.model.value = null"
                    >
                        <i class="fa-regular fa-xmark" />
                    </a>
                </div>
            </template>
        </SelectContact>
    </FormElement>
</template>
